import { CarshareApiService } from 'src/apis/CarshareApiService'
import { DownloadableReports } from 'src/apis/CarshareResources'
import { TableMenuItem } from 'src/components/ManagementTable/TableMenuItem'
import { StyledManagementTable, StyledPaper } from './StyledReports'
// @ts-ignore

export type ReportsDownloadsProps = {
  //
}

export const ReportsDownloads: React.FC<ReportsDownloadsProps> = () => {
  const onDownloadReport = (reportType: DownloadableReports) => {
    const downloadLink = CarshareApiService.getDownloadLink(reportType)

    window.open(downloadLink, '_blank')
  }

  return (
    <StyledPaper>
      <StyledManagementTable
        title={'Downloadable Reports'}
      >
        <TableMenuItem
          actionText="Download"
          onClick={() => onDownloadReport('getCustomerReport')}
        >
          Customer Report
        </TableMenuItem>
        <TableMenuItem
          actionText="Download"
          onClick={() => onDownloadReport('getVehicleReport')}
        >
          Vehicle Report
        </TableMenuItem>
        <TableMenuItem
          actionText="Download"
          onClick={() => onDownloadReport('getVehicleUtilisationReport')}
        >
          Vehicle Utilisation Report
        </TableMenuItem>
      </StyledManagementTable>
    </StyledPaper>

  )
}
