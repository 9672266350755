import { faCar, faFileChartColumn, faGear, faLayerGroup, faObjectsColumn } from '@fortawesome/pro-solid-svg-icons'
import { useMediaQuery, useTheme } from '@mui/material'
import { DesktopNavItem } from 'src/components/DesktopNavSidebar/DesktopNavItem'
import { DesktopNavSidebar } from 'src/components/DesktopNavSidebar/DesktopNavSidebar'
import { setNavSidebarOpen, setOperationActiveVehicleId } from 'src/store/reducers/settings'
import { useAppDispatch, useAppSelector } from 'src/store/store'

export type NavigationFragmentProps = {
  //
}

export const NavigationFragment: React.FC<NavigationFragmentProps> = () => {
  const isNavOpen = useAppSelector((state) => state.settings.navSidebarOpen)
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const onNavItemSelect = () => {
    if (isMobile) {
      dispatch(setNavSidebarOpen(false))

      // on Mobile, we want to reset the active vehicle id when navigating away from the operations page
      dispatch(setOperationActiveVehicleId(null))
    }
  }

  return (
    <DesktopNavSidebar open={isNavOpen} logoHref="/dashboard">
      <DesktopNavItem href="/dashboard" onClick={onNavItemSelect} icon={faObjectsColumn}>
        Dashboard
      </DesktopNavItem>
      <DesktopNavItem href="/dashboard/operations" onClick={onNavItemSelect} icon={faCar}>
        Operations
      </DesktopNavItem>
      <DesktopNavItem href="/dashboard/management/customers" onClick={onNavItemSelect} icon={faLayerGroup}>
        Management
      </DesktopNavItem>
      <DesktopNavItem href="/dashboard/reports/downloads" onClick={onNavItemSelect} icon={faFileChartColumn}>
        Reports
      </DesktopNavItem>
      <DesktopNavItem href="/dashboard/settings" onClick={onNavItemSelect} icon={faGear}>
        Settings
      </DesktopNavItem>
    </DesktopNavSidebar>
  )
}