import { Grid, Typography, styled } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import { CalendarBooking } from 'src/components/OperationsCalander/CalendarBooking'
import { CalendarBookingList } from 'src/components/OperationsCalander/CalendarBookingList'
import { CalendarBookingRow, Booking as CalendarBookingType } from 'src/components/OperationsCalander/CalendarBookingRow'
import { CalendarVehicle } from 'src/components/OperationsCalander/CalendarVehicle'
import { CalendarVehicleList } from 'src/components/OperationsCalander/CalendarVehicleList'
import { OperationsCalendar, OperationsCalendarProps } from 'src/components/OperationsCalander/OperationsCalendar'
import { useVehicleSearchContext } from 'src/components/OperationsCalander/VehicleSearchContext'
import { createDayjsObject } from 'src/libs/formatDateAndTime'
import { bookingsSelectors } from 'src/store/adapters/bookings'
import { vehiclesSelectors } from 'src/store/adapters/vehicles'
import { useAppSelector } from 'src/store/store'
import { pxToRem } from 'src/styles/themes'
import { Booking } from 'src/types/Booking'


type CalendarFragmentProps = Omit<OperationsCalendarProps, 'children'> & {
  onBookingClick?: (bookingId: string) => void
  rowsPerPage: number
  currentPage: number
  setCurrentPage: (page: number) => void
  setRowsPerPage: (rowsPerPage: number) => void
}

export const CalendarFragment: React.FC<CalendarFragmentProps> = ({ currentDay, onBookingClick, rowsPerPage, currentPage, setCurrentPage, setRowsPerPage, ...props }) => {
  const { vehicleSearchString } = useVehicleSearchContext()
  const activeOrgUnit = useAppSelector((state) => state.settings.activeOrgUnit)
  const operationActiveVehicleId = useAppSelector((state) => state.settings.operationActiveVehicleId) || ''
  const bookings = useAppSelector((state) => bookingsSelectors.selectAll(state))
  const vehicles = useAppSelector((state) => vehiclesSelectors.selectAll(state)).filter((vehicle) => {
    return vehicle.vehicle_hidden === '0' && (vehicle.vehicle_rego_number.toLowerCase().includes(vehicleSearchString.toLowerCase()) ||
      vehicle.make_name.toLowerCase().includes(vehicleSearchString.toLowerCase()) ||
      vehicle.model_name.toLowerCase().includes(vehicleSearchString.toLowerCase()) ||
      vehicle.VEHICLE_ID.toLowerCase().includes(vehicleSearchString.toLowerCase()))
  })

  const statusMap: Record<Booking['status'], CalendarBookingType['status']> = {
    '': 'success',
    'Booked': 'success',
    'Late pickup': 'warning',
    'In Use': 'success',
    'Late return': 'error',
    'Completed on-time': 'success',
    'Completed late': 'warning',
  }

  const onCalendarBookingClick = (bookingId: string) => {
    if (onBookingClick) {
      onBookingClick(bookingId)
    }
  }

  //Pagination
  const start = currentPage * rowsPerPage + 1
  const end = Math.min((currentPage + 1) * rowsPerPage, vehicles.length)

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setCurrentPage(0)
  }

  useEffect(() => {
    setCurrentPage(0)
  }, [vehicleSearchString, activeOrgUnit])

  return (
    <StyledOperationCalendar
      currentDay={currentDay}
      selectedId={operationActiveVehicleId}
      paginatiionProps={{
        count: vehicles.length,
        page: currentPage,
        rowsPerPage: rowsPerPage,
        onPageChange: handleChangePage,
        onRowsPerPageChange: handleChangeRowsPerPage,
      }}
      {...props}
    >
      <CalendarVehicleList
        title={'Vehicles'}
      >
        {vehicles.length === 0 && (
          <Grid item sx={{ height: pxToRem(62) }}>
            <StyledNoVehicleText variant="body2">
              No vehicles found
            </StyledNoVehicleText>
          </Grid>
        )}
        {vehicles.slice(start - 1, end).map(({ model_name, make_name, vehicle_rego_number, vehicle_colour_code, file_image_src_data, VEHICLE_ID }, idx) => (
          <CalendarVehicle
            key={idx}
            vehicleId={VEHICLE_ID}
            vehicleRegoNumber={vehicle_rego_number}
            imgSrcUrl={file_image_src_data && file_image_src_data.substring(4, file_image_src_data.length - 1) || ''}
            makeName={make_name}
            modelName={model_name}
            vehicleColorCode={vehicle_colour_code}
          />
        ))}
      </CalendarVehicleList>
      <CalendarBookingList>
        {vehicles.slice(start - 1, end).map(({ VEHICLE_ID }, idx) => {
          const vehicle_bookings = bookings.filter((booking) => booking.VEHICLE_ID === VEHICLE_ID).map((booking) => {
            const { BOOKING_ID, booking_time_pickup_hour, booking_date_pickup, booking_time_pickup_minutes, booking_date_dropoff, booking_time_dropoff_hour, booking_time_dropoff_minutes, status } = booking

            const isCancelled = booking.booking_cancelled !== '0000-00-00 00:00:00'

            return {
              id: BOOKING_ID,
              startDate: booking_date_pickup,
              startHour: +booking_time_pickup_hour,
              startMinute: +booking_time_pickup_minutes,
              endDate: booking_date_dropoff,
              endHour: +booking_time_dropoff_hour,
              endMinute: +booking_time_dropoff_minutes,
              status: isCancelled ? 'cancelled' : statusMap[status],
            }
          })

          // Keep track of the earliest startDate, startHour, startMinute and the latest endDate, endHour, and endMinute
          let prevEnd: dayjs.Dayjs | null = null

          return (
            <CalendarBookingRow
              key={idx}
              bookings={vehicle_bookings}
              vehicleId={VEHICLE_ID}
            >
              {({ bookingsForThisHour }) => {
                return bookingsForThisHour.map((booking, idx2) => {
                  let additionalProps = {}

                  // Check whether booking is overlapped with the previous booking
                  const currentStart = createDayjsObject(booking.startDate, booking.startHour, booking.startMinute)
                  const currentEnd = createDayjsObject(booking.endDate, booking.endHour, booking.endMinute)

                  if (idx2 !== 0 && prevEnd && currentStart.isBefore(prevEnd)) {
                    additionalProps = {
                      isOverlapped: true,
                    }

                    if (prevEnd.isBefore(currentEnd)) {
                      prevEnd = currentEnd
                    }
                  } else {
                    prevEnd = currentEnd
                  }

                  // Check whether there is a booking click event
                  if (onBookingClick) {
                    additionalProps = {
                      ...additionalProps,
                      onClick: () => onCalendarBookingClick(booking.id),
                    }
                  }

                  return (
                    <CalendarBooking
                      key={idx2}
                      booking={booking}
                      {...additionalProps}
                    />
                  )
                })
              }}
            </CalendarBookingRow>
          )
        })}
      </CalendarBookingList>
    </StyledOperationCalendar>
  )
}

const StyledNoVehicleText = styled(Typography)`
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.palette.text.secondary};
  margin-top: ${props => props.theme.typography.pxToRem(32)};
`

const StyledOperationCalendar = styled(OperationsCalendar)`
  height: 100%;
`