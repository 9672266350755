import { faClose } from '@fortawesome/pro-regular-svg-icons'
import { Grid, styled, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { useAlertContext } from 'src/components/Alert/AlertProvider'
import { Button } from 'src/components/Button/Button'
import { Icon } from 'src/components/Icon/Icon'
import { useManagementTableContext } from 'src/components/ManagementTable/ManagementTableContext'
import { QuillEditor } from 'src/components/Quill/QuillEditor'
import { useApiRequest } from 'src/hooks/useApiRequest'
import { pxToRem } from 'src/styles/themes'
import { Content } from 'src/types/Content'
import { StyledCloseButton, StyledContainer } from '../../Management/StyledDetailsFragment'


type ContentDetailsFragmentProps = {
  //
}

export const ContentDetailsFragment: React.FC<ContentDetailsFragmentProps> = () => {
  const { selectedId, setSelectedId } = useManagementTableContext()
  const [selectedContent, setSelectedContent] = useState<Content | null>(null)
  const { loading, request } = useApiRequest()
  const { setSuccessMessage } = useAlertContext()

  const onContentChange = () => {
    const updateContentHandler = () => {
      const payload = {
        CONTENT_ID: selectedContent?.CONTENT_ID,
        content_code: selectedId,
        content_name: selectedId,
        content_data: selectedContent?.content_data,
      }

      return CarshareApiService.post<Content>('addContent', payload)
    }

    request(updateContentHandler, () => {
      setSuccessMessage(`Content ${selectedId} is updated successfully.`)
    })
  }

  const setHtmlContent = (content_data: string) => {
    setSelectedContent((prev) => ({
      ...prev,
      content_data,
    } as Content))
  }

  // Initialize html content
  useEffect(() => {
    const getContentsHandler = () => {
      return CarshareApiService.get<Content>('getContent', {
        content_code: selectedId,
      })
    }

    if (selectedId) {
      request(getContentsHandler, (content) => {
        if (content.length === 0) return

        setSelectedContent(content[0])
        setHtmlContent(content[0]?.content_data)
      })
    }
  }, [selectedId])

  return (
    <StyledContainer>
      <Grid container justifyContent={'space-between'}>
        <Grid item>
          <Typography variant="h6">{selectedId} Page</Typography>
        </Grid>
        <Grid item>
          <StyledCloseButton onClick={() => setSelectedId(null)}>
            <Icon icon={faClose} />
          </StyledCloseButton>
        </Grid>
      </Grid>

      {<QuillEditor
        options={{
          placeholder: 'Compose onboarding content...',
        }}
        htmlContent={selectedContent?.content_data || ''}
        setHtmlContent={setHtmlContent}
        sx={{ marginTop: pxToRem(16) }}
      />}

      <StyledSaveButton
        fullWidth
        primary
        onClick={onContentChange}
        disabled={!selectedContent?.content_data || loading}
      >
        Save Changes
      </StyledSaveButton>
    </StyledContainer>
  )
}

const StyledSaveButton = styled(Button)`
  margin-top: ${(props) => props.theme.typography.pxToRem(16)};
`