import { styled } from '@mui/material'
import Quill from 'quill'
import 'quill/dist/quill.snow.css'
import React, { ComponentProps, useEffect, useRef, useState } from 'react'
import { Modal } from '../Modal/Modal'
import { QuillContext } from './QuillContext'
import { QuillPreview } from './QuillPreview'
import { QuillToolbar } from './QuillToolbar'


export type QuillProps = ComponentProps<typeof StyledQuillContainer> & {
  options: {
    placeholder: string
  }
  htmlContent: string
  setHtmlContent: (html: string) => void
}

export const QuillEditor: React.FC<QuillProps> = ({ options, htmlContent, setHtmlContent, ...props }) => {
  const quillRef = useRef<HTMLDivElement>(null)
  const [quill, setQuill] = useState<Quill | null>(null)
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [Initialized, setInitialized] = useState<boolean>(false)

  const togglePreview = () => {
    setShowPreview((prev) => !prev)
  }

  useEffect(() => {
    const hasQuill = document?.querySelector('.ql-editor')

    if (!hasQuill) {
      const quillObj = new Quill('#quill-editor', {
        modules: {
          toolbar: '#toolbar',
        },
        theme: 'snow',
        ...options,
      })

      // Add event listeners to update content
      quillObj.on('text-change', () => {
        const html = quillRef.current?.querySelector('.ql-editor')?.innerHTML

        setHtmlContent(html || '')
      })

      setQuill(quillObj)
    }
  }, [setHtmlContent])

  // Initialize Quill editor
  useEffect(() => {
    if (quill && !Initialized && htmlContent && htmlContent.trim() !== '') {
      quill.clipboard.dangerouslyPasteHTML(htmlContent)
      setInitialized(true)
    }
  }, [quill, htmlContent, Initialized])

  return (
    <QuillContext.Provider
      value={{ quill, showPreview, togglePreview, htmlContent }}
    >
      <StyledQuillContainer {...props}>
        <QuillToolbar />
        <StyledQuillEditor
          id="quill-editor"
          ref={quillRef}
        >
        </StyledQuillEditor>
        <Modal
          open={showPreview}
          title={'Preview'}
          disabledBackdropClose={false}
          onClose={togglePreview}
        >
          <QuillPreview htmlContent={htmlContent} />
        </Modal>

      </StyledQuillContainer>
    </QuillContext.Provider>
  )
}

const StyledQuillContainer = styled('div')`
  > .ql-toolbar {
    border-radius: ${props => props.theme.typography.pxToRem(8)} ${props => props.theme.typography.pxToRem(8)} 0 0;
  }

  .ql-container {
    font-family: 'Inter', 'Roboto', sans-serif !important;
  }
`

const StyledQuillEditor = styled('div')`
  border-radius: 0 0 ${props => props.theme.typography.pxToRem(8)} ${props => props.theme.typography.pxToRem(8)};

  .ql-editor {
    line-height: 1.5;
    font-size: ${props => props.theme.typography.pxToRem(16)};
    font-weight: 300;
    letter-spacing: 0.02em;
    
    & p {
      margin-bottom: ${props => props.theme.typography.pxToRem(16)};

      .ql-size-large {
        font-size: ${props => props.theme.typography.pxToRem(20)};
      }

      .ql-size-huge {
        font-size: ${props => props.theme.typography.pxToRem(24)};
      }
    }

    & ul {
      padding-left: ${props => props.theme.typography.pxToRem(32)};
    }
    
    & strong {
      font-weight: 500;
      margin-bottom: 0 !important;
    }
  }
`
