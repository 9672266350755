
const carshareAuthResources = {
  login: '/api/auth/login/',
  logout: '/api/auth/logout/',
  resetPassword: '/api/common/reset_pw/replace/',
  changePassword: '/api/common/reset_pw/update/',
  session: '/api/auth/session/',

  getAccount: '/api/common/accounts/',
  updateAccount: '/api/common/accounts/update/',
  updateAccountPassword: '/api/common/accounts/Change/password/',
}

const carshareOrganisationResources = {
  retrieveOrgUnits: '/api/carshare/org_units/',
  retrieveAllSelect: '/api/multiselect/',
  addOrgUnit: '/api/carshare/org_units/create/',
  editOrgUnit: '/api/carshare/org_units/update/',

  getOrgUnitMembers: '/api/carshare/org_units/members/',
  getOrgUnitManagers: '/api/carshare/org_units/managers/',

  getTeamMembers: '/api/carshare/team_members/',
  editTeamMember: '/api/carshare/team_members/replace/',
  addTeamMember: '/api/carshare/team_members/enrol/',
  getTeamMemberOrgUnits: '/api/carshare/team_members/org_units/',
  addTeamMemberOrgUnit: '/api/carshare/org_units/managers/create/',
  removeTeamMemberOrgUnit: '/api/carshare/org_units/managers/delete/',

  getTeamMemberPreferences: '/api/carshare/preferences/',
  updateTeamMemberPreferences: '/api/carshare/preferences/replace/',

  getAttributes: '/api/carshare/attributes/',
  addAttribute: '/api/carshare/attributes/replace',
  editAttribute: '/api/carshare/attributes/replace',
  removeAttribute: '/api/carshare/attributes/delete',

  getThirdParties: '/api/carshare/third_parties/',
  addThirdParty: 'api/carshare/third_parties/replace/',
  editThirdParty: 'api/carshare/third_parties/replace/',

  getPassengers: '/api/carshare/customers/bookings/passengers/',
  addPassenger: '/api/carshare/customers/bookings/passengers/replace/',
  editPassenger: '/api/carshare/customers/bookings/passengers/replace/',
  getNotifications: '/api/carshare/team_members/notifications/',
  dismissNotification: '/api/common/notifications/mark_read/',

  getContent: '/api/settings/content/',
  addContent: '/api/settings/content/replace/',
  editContent: '/api/settings/content/replace/',

  control: '/api/carshare/org_units/vehicles/control/',
}

const carshareDashboardResources = {
  dayBookings: '/api/carshare/org_units/day_bookings/',
  getVehiclesLastInfo: '/api/carshare/org_units/vehicles/positions/last/',
  getDayBookings: '/api/carshare/org_units/day_bookings/',
  getDayStats: '/api/carshare/org_units/day_stats/',
  getOnTimeRunning: '/api/carshare/customers/bookings/on_time/running/',
}

const carshareOperationsResources = {
  selectVehicles: '/api/selects/carshare/org_units/vehicles/',
  selectCustomers: '/api/selects/carshare/org_units/customers/',
  getBookings: '/api/carshare/customers/bookings/',
  getBookingsByDate: '/api/carshare/customers/bookings/by_date/',
  addBookings: '/api/carshare/customers/bookings/create/',
  editBookings: '/api/carshare/customers/bookings/update/',
  cancelBooking: '/api/carshare/customers/bookings/cancel/',

  getCustomers: '/api/carshare/customers/',
  addCustomer: '/api/carshare/customers/enrol/',
  editCustomer: '/api/carshare/customers/replace/',
  getCustomerOrgUnits: '/api/carshare/customers/org_units/',
  addCustomerOrgUnit: '/api/carshare/org_units/members/create/',
  removeCustomerOrgUnit: '/api/carshare/org_units/members/delete/',
  getCustomerAttributes: '/api/carshare/customers/attributes/',
  addCustomerAttribute: '/api/carshare/customers/attributes/replace/',
  removeCustomerAttribute: '/api/carshare/customers/attributes/delete/',

  getLocations: '/api/carshare/org_units/locations/',
  addLocation: '/api/carshare/org_units/locations/replace/',
  editLocation: '/api/carshare/org_units/locations/replace/',
  getLocationFacility: '/api/carshare/org_units/locations/facilities/',
  addLocationFacility: '/api/carshare/org_units/locations/facilities/replace/',
  removeLocationFacility: '/api/carshare/org_units/locations/facilities/delete/',

  getVehicles: '/api/carshare/org_units/vehicles/',
  getVehiclesStrict: '/api/carshare/org_units/vehicles/strict/',
  addVehicle: '/api/carshare/org_units/vehicles/replace/',
  editVehicle: '/api/carshare/org_units/vehicles/replace/',
  getVehicleAttribute: '/api/carshare/org_units/vehicles/attributes/',
  addVehicleAttribute: '/api/carshare/org_units/vehicles/attributes/replace/',
  removeVehicleAttribute: '/api/carshare/org_units/vehicles/attributes/delete/',
  addVehicleImage: '/api/carshare/org_units/vehicles/images/import/',

  getVehicleTelematicsEvents: '/api/carshare/org_units/vehicles/events/',

  getSelects: '/api/multiselect/',
}

const carshareReportResources = {
  getCustomerReport: '/api/carshare/reports/customers/csv/',
  getVehicleReport: '/api/carshare/reports/vehicles/csv/',  
  getVehicleUtilisationReport: '/api/carshare/reports/vehicles/utilisation/csv',
}

export type DownloadableReports = keyof typeof carshareReportResources

export const carshareResources = {
  ...carshareAuthResources,
  ...carshareOrganisationResources,
  ...carshareDashboardResources,
  ...carshareReportResources,
  ...carshareOperationsResources,
}
