import { createAsyncThunk } from '@reduxjs/toolkit'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { VehicleDetail } from 'src/types/Vehicle'
import { replaceAllVehicles } from '../reducers/vehicles'

type SetupPayload = {
  ORG_UNIT_ID?: string

  //If true, returns only vehicles within the ORG_UNIT_ID and ignore 'All'
  strict?: boolean 
}

export const setupVehicles = createAsyncThunk('vehicles/setup', async ({ ORG_UNIT_ID, strict }: SetupPayload, { dispatch }) => {
  // Retrieve Vehicles
  const vehicles = await CarshareApiService.post<VehicleDetail>(strict ? 'getVehiclesStrict' : 'getVehicles', {
    limit: 1000,
    ORG_UNIT_ID,
  })

  if (vehicles.results) {
    dispatch(replaceAllVehicles(vehicles.results))
  }
})